/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/CompaniesSlice';
import {
  actions as topEntitiesSliceActions,
  actions as topCompaniesActions,
} from 'Slice/TopCompanyEntitySlice';
import { actions as searchActions } from 'Slice/GlobalSearchResultsSlice';
import { actions as onboardingActions } from 'Slice/OnboardingSlice';
import { actions as branchActions } from 'Slice/BranchSlice';
import { actions as alertActions } from 'Slice/AlertsSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';
import _ from 'lodash';

import {
  API,
  FILE_DOWNLOAD_FAIL,
  FILE_DOWNLOAD_SUCCESS,
  ONBOARDING_ROUTE,
} from 'Utils/Constants';
import {
  AccountPlanHistoryRequest,
  AccountPlanStatusRequest,
  CompanySWOTAnalysis,
  CompanySWOTRefresh,
  CompareCompaniesRequest,
  DeleteAccountPlanRequest,
  DownloadCompanyProfileRequest,
  FollowAndUnFollowCompaniesRequest,
  GenerateAccountPlanRequest,
  GetAccountPlanRequest,
  GetCompaniesRequest,
  GetCompanyNewsRequest,
  GetPersonProfileRequest,
  GetProfileRequest,
  NotableStaffRequest,
  RecommendedDesignationsRequest,
  TopCompanyEntityRequest,
} from 'Types/RequestBodies';
import {
  CompaniesDataResponse,
  CompanyDownloadProfileResponse,
  CompanyFilingResponse,
  CompanyHighlightsIntentResponse,
  CompanyProfileResponse,
  CompanyPulseResponse,
  CompanyStockChartsResponse,
  CompanyStaff,
  FollowCompanyResponse,
  RecommendedCompaniesDataResponse,
  RemoveCompanyResponse,
  CompanyNewsResponse,
  CompanyNotableStaffResponse,
  ExportRequest,
  ExportNotableStaffResponse,
  CompareCompaniesDataResponse,
  CompanySWOTResponse,
  SWOTRefreshResponse,
  GeneratedAccountPlan,
  UserAccountPlanDetails,
  CompareCompaniesExportResponse,
  CompanyBenefitsResponse,

} from 'Types/CompaniesTypes';
import { actions as helperActions } from 'Slice/HelperSlice';
import {
  getCurrentTime,
  handleException,
  isFreeUser,
  setLocalStorageValue,
  valueToObject,
} from 'Utils/UtilityFunctions';
import { TopEntitiesResponse } from 'Types/TopEntitiesTypes';
import { PersonDataResponse, WorkbenchStatsResponse } from 'Types/PersonTypes';
import { Filter } from 'Types/FilterTypes';
import {
  AccountPlanFormResponse, AccountPlanHistoryResponse,
  AccountPlanStatusResponse,
  GenerateAccountPlanResponse, RecommendedDesignationsResponse,
} from 'Types/ResponseBodies';
import { CompanyVideosResponse } from '../Types/CompaniesTypes.d';
import { GetCompanyVideosRequest } from '../Types/RequestBodies.d';

export function* getCompanies(dispatched: PayloadAction<GetCompaniesRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: CompaniesDataResponse = yield call(
      axios.post,
      API.GET_LAUNCHPAD_COMPANIES,
      dispatched?.payload,
    );

    if (response?.status === true) {
      if (
        dispatched?.payload?.searchTerm
        && dispatched?.payload?.searchTerm.trim() !== ''
      ) {
        yield put(actions.setSearchedCompanies(response));
      } else {
        yield put(actions.setCompanies(response));
      }
      yield put(
        helperActions.callFlurryEvent(
          valueToObject('companies_page_load', startTime, {
            ...dispatched?.payload,
            number_of_companies: response.response.companiesCount,
          }),
        ),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRecommendedCompanies(
  dispatched: PayloadAction<GetCompaniesRequest>,
) {
  try {
    const response: RecommendedCompaniesDataResponse = yield call(
      axios.post,
      API.GET_RECOMMENDED_COMPANIES,
      dispatched?.payload,
    );

    if (response?.status === true) {
      const startTime = getCurrentTime();
      yield put(actions.setRecommendedCompanies(response));
      yield put(
        helperActions.callFlurryEvent(
          valueToObject(
            'companies_recomended_company',
            startTime,
            dispatched?.payload,
          ),
        ),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* followCompany(
  dispatched: PayloadAction<FollowAndUnFollowCompaniesRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: FollowCompanyResponse = yield call(
      axios.post,
      API.FOLLOW_COMPANIES,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(
        topEntitiesSliceActions.handleCompanyFollowUnFollow(
          response.response.companyIds,
        ),
      );
      yield put(actions.handleCompanyFollowed(response));
      yield put(searchActions.searchCompaniesFollowHandling(response));
      yield put(onboardingActions.setCompanyFollowed(response));
      yield put(
        helperActions.callFlurryEvent(
          valueToObject(
            'companies_company_added',
            startTime,
            dispatched?.payload,
          ),
        ),
      );
      if (!response?.message.includes('Unauthorized')) {
        yield put(
          helperActions.setNotification({
            message: response?.message,
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: true,
          }),
        );
      }
    } else {
      yield put(
        helperActions.setNotification({
          message: response?.message,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* unFollowCompanies(
  dispatched: PayloadAction<FollowAndUnFollowCompaniesRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: RemoveCompanyResponse = yield call(
      axios.post,
      API.REMOVE_COMPANIES,
      dispatched?.payload,
    );

    if (response?.status === true) {
      const {
        response: { subcribedCompaniesCount },
      } = response;
      const url = new URL(window.location.href);
      const path = url.pathname;
      if (!subcribedCompaniesCount && path !== ONBOARDING_ROUTE) {
        setLocalStorageValue('skipOnBoarding', '0');
        window.location.href = ONBOARDING_ROUTE;
      }
      yield put(
        helperActions.setNotification({
          message: response?.message,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
        }),
      );
      yield put(
        alertActions.handleCompanyUnfollow(response.response.companyIds),
      );
      yield put(
        topEntitiesSliceActions.handleCompanyFollowUnFollow(
          response.response.companyIds,
        ),
      );
      yield put(actions.handleCompanyUnFollowed(response));
      yield put(
        searchActions.searchCompaniesUnfollowHandling(
          response.response.companyIds,
        ),
      );
      yield put(
        onboardingActions.setCompanyUnFollowed(response.response.companyIds),
      );
      const companyArray = String(dispatched.payload.companyId).split(',');
      _.unset(dispatched.payload, 'companyId');
      yield put(
        helperActions.callFlurryEvent(
          valueToObject('companies_company_remove', startTime, {
            ...dispatched?.payload,
            ...(companyArray.length > 1 && {
              company_ids: companyArray.join(','),
            }),
            ...(companyArray.length === 1 && {
              company_id: companyArray.join(','),
            }),
          }),
        ),
      );
    } else {
      yield put(
        helperActions.setNotification({
          message: response?.message,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyBenefits(dispatched: PayloadAction<GetProfileRequest>) {
  try {
    const response: CompanyBenefitsResponse = yield call(
      axios.post,
      API.COMPANY_BENEFITS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setCompanyBenefits(response.response?.benefits));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyProfile(
  dispatched: PayloadAction<GetProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const { payload } = dispatched;
    const parameters = { ...payload };
    if ('source' in parameters) {
      _.unset(parameters, 'source');
    }
    const response: CompanyProfileResponse = yield call(
      axios.post,
      API.COMPANY_PROFILE,
      parameters,
    );

    if (response?.status === true) {
      const { companyName } = response.response;
      yield put(actions.setCompanyProfile(response?.response));
      if (payload?.source) {
        yield put(branchActions.reset());
      }
      if (companyName) {
        yield put(
          helperActions.callFlurryEvent(
            valueToObject('company_page_load', startTime, {
              ...payload,
              ...(payload.source && {
                article_entity_name: companyName,
              }),
              company_name: companyName,
            }),
          ),
        );

        yield put(
          actions.setCompanyDetailFlurry({
            ...parameters,
            company_name: companyName,
            loading_time: _.round(getCurrentTime() - startTime, 2),
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanySecFilings(
  dispatched: PayloadAction<GetProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyFilingResponse = yield call(
      axios.post,
      API.COMPANY_SEC_FILINGS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setCompanySecFilings(response.response));
      yield put(
        helperActions.callFlurryEvent(
          valueToObject('company_sec_files', startTime, dispatched?.payload),
        ),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyPulse(dispatched: PayloadAction<GetProfileRequest>) {
  try {
    const response: CompanyPulseResponse = yield call(
      axios.post,
      API.COMPANY_PULSE,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setCompanyPulse(response.response.companyPulse));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyIntentData(
  dispatched: PayloadAction<GetProfileRequest>,
) {
  try {
    const response: CompanyHighlightsIntentResponse = yield call(
      axios.post,
      API.COMPANY_INTENT_DATA,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setCompanyIntentData(response.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyStaff(dispatched: PayloadAction<GetProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyStaff = yield call(
      axios.post,
      API.COMPANY_STAFF,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setCompanyStaff(response));
      yield put(
        actions.setStaffFlurry({
          ...dispatched?.payload,
          loading_time: _.round(getCurrentTime() - startTime, 2),
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getDownloadProfile(
  dispatched: PayloadAction<DownloadCompanyProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyDownloadProfileResponse = yield call(
      axios.post,
      API.COMPANY_DOWNLOAD_PROFILE,
      dispatched?.payload,
    );
    if (response?.status === true) {
      if (dispatched.payload.ispdf === 1) yield put(actions.setPDFProfile(response.response));
      else if (dispatched.payload.ispdf !== 1) yield put(actions.setPPTProfile(response.response));
      if (response.response.showPlanPopup !== 3) {
        yield put(
          helperActions.callFlurryEvent(
            valueToObject('company_ppt_pdf', startTime, {
              ...dispatched?.payload,
              type: dispatched?.payload.ispdf === 1 ? 'pdf' : 'ppt',
            }),
          ),
        );
      }
      if (response.response?.url) {
        yield put(
          helperActions.setNotification({
            message: FILE_DOWNLOAD_SUCCESS,
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: true,
          }),
        );
      } else if (!isFreeUser() && response.response?.showPlanPopup !== 3) {
        yield put(
          helperActions.setNotification({
            message: FILE_DOWNLOAD_FAIL,
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: false,
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getStockChart(dispatched: PayloadAction<GetProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyStockChartsResponse = yield call(
      axios.post,
      API.COMPANY_STOCK_CHART,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setStockChart(response.response));
      yield put(
        actions.setFinanialFlurry({
          ...dispatched?.payload,
          company_name: response.response.companyName,
          loading_time: _.round(getCurrentTime() - startTime, 2),
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyNews(
  dispatched: PayloadAction<GetCompanyNewsRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyNewsResponse = yield call(
      axios.post,
      API.COMPANY_NEWS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setCompanyNews(response.response.companyNews));
      yield put(actions.setNewsFilters(response.response.filters));
      if (dispatched.payload.page === 1) {
        yield put(
          actions.setNewsFlurry({
            ...dispatched?.payload,
            company_name: response.response.companyName,
            loading_time: _.round(getCurrentTime() - startTime, 2),
          }),
        );
      } else {
        yield put(
          helperActions.callFlurryEvent(
            valueToObject('company_news', startTime, {
              ...dispatched?.payload,
              company_name: response.response.companyName,
            }),
          ),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyVideos(
  dispatched: PayloadAction<GetCompanyVideosRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const response: CompanyVideosResponse = yield call(
      axios.post,
      API.COMPANY_VIDEOS,
      dispatched?.payload,
    );
    const { companyName, companyVideo } = response.response;
    if (response?.status === true) {
      yield put(actions.setCompanyVideos(companyVideo));
      if (dispatched.payload.page === 1) {
        yield put(
          actions.setVideoFlurry({
            ...dispatched?.payload,
            company_name: companyName,
            loading_time: _.round(getCurrentTime() - startTime, 2),
          }),
        );
      } else {
        yield put(
          helperActions.callFlurryEvent(
            valueToObject('company_videos', startTime, {
              ...dispatched?.payload,
              company_name: companyName,
            }),
          ),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTopCompanyEntities(
  dispatched: PayloadAction<TopCompanyEntityRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const payload = dispatched?.payload;
    const response: TopEntitiesResponse = yield call(
      axios.post,
      API.COMPANY_TOP_ENTITIES,
      payload,
    );
    if (response?.status === true) {
      yield put(topCompaniesActions.setTopCompanyEntities(response));
      yield put(
        actions.setCompetitorsFlurry({
          ...dispatched?.payload,
          company_name: response.response.companyName,
          loading_time: _.round(getCurrentTime() - startTime, 2),
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getNotableStaff(
  dispatched: PayloadAction<NotableStaffRequest>,
) {
  try {
    const startTime = getCurrentTime();
    const payload = dispatched?.payload;
    const obj = {
      page: payload.page,
      companyId: payload.companyId,
      ...(payload.screenName && { screenName: payload.screenName }),
      ...(payload.filters && {
        filters: payload.filters
          .filter((item) => item.isSelected)
          .map((item) => item.filterId)
          .join(','),
      }),
    };
    const response: CompanyNotableStaffResponse = yield call(
      axios.post,
      API.GET_NOTABLE_STAFF,
      obj,
    );
    if (response?.status === true) {
      if (payload.screenName === 'sap') {
        yield put(actions.setSapNotableStaff(response.response[0]));
      } else {
        yield put(actions.setNotableStaff(response.response[0]));
        yield put(
          helperActions.callFlurryEvent(
            valueToObject('company_notable_staff', startTime, {
              ...obj,
            }),
          ),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getBuyerStaffResponse(
  dispatched: PayloadAction<NotableStaffRequest>,
) {
  try {
    // const startTime = getCurrentTime();
    const payload = dispatched?.payload;
    const obj = {
      page: payload.page,
      companyId: payload.companyId,
      ...(payload.screenName && { screenName: payload.screenName }),
      ...(payload.filters && {
        filters: payload.filters
          .filter((item) => item.isSelected)
          .map((item) => item.filterId)
          .join(','),
      }),
    };
    const response: CompanyNotableStaffResponse = yield call(
      axios.post,
      API.GET_NOTABLE_STAFF,
      obj,
    );
    if (response?.status === true) {
      // yield put(actions.setSapNotableStaff(response.response[0]));
      yield put(actions.setSapBuyerStaff(response.response[0]));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCompanyWorkbenchStats(dispatched: PayloadAction<number>) {
  const data = {
    companyId: dispatched?.payload,
  };
  try {
    const res: WorkbenchStatsResponse = yield call(
      axios.post,
      API.GET_COMPANY_PROFILE_WB_STATS,
      data,
    );
    if (res?.status === true) {
      const statTiles = res?.response?.filter(
        (stat) => stat.type !== 'engagement' && stat.type !== 'contactId',
      );
      const engagementGraph = res?.response?.find(
        (stat) => stat.type === 'engagement',
      );
      if (statTiles && engagementGraph) {
        const { data: seriesData } = engagementGraph;
        if (seriesData) {
          const allZero = statTiles.every((tile) => tile.value === 0);
          if (allZero) {
            yield put(actions.setEmptyCompanyWorkbenchStats());
          } else if (!isFreeUser()) {
            yield put(actions.setCompanyWorkbenchStats(res?.response));
          }
        }
      }
    } else {
      yield put(actions.failCompanyWorkbenchStats('something went wrong'));
    }
  } catch (err) {
    yield put(actions.failCompanyWorkbenchStats('something went wrong'));
    console.log(err);
  }
}

export function* getExportNotableStaff(
  dispatched: PayloadAction<ExportRequest>,
) {
  const startTime = getCurrentTime();
  let obj = {};
  if (dispatched.payload) {
    const { companyId, companyName, filters } = dispatched.payload;
    obj = {
      companyId,
      companyName,
      ...(filters && {
        filters: filters?.map((it: Filter) => it.filterId).join(','),
      }),
    };
  }
  try {
    const response: ExportNotableStaffResponse = yield call(
      axios.post,
      API.NOTABLE_STAFF_EXPORT,
      obj,
    );
    if (response?.status === true) {
      yield put(actions.setExportNotableStaff(response.response));
      yield put(
        helperActions.callFlurryEvent(
          valueToObject('notable_staff_export', startTime, {
            ...dispatched?.payload,
          }),
        ),
      );
      yield put(helperActions.setNotification({
        message: FILE_DOWNLOAD_SUCCESS,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(helperActions.setNotification({
        message: 'File download failed',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getExportCompanies(dispatched: PayloadAction<number[]>) {
  const { payload } = dispatched;
  try {
    const response: ExportNotableStaffResponse = yield call(
      axios.post,
      API.COMPANIES_EXPORT,
      { selectedIds: payload.join(', ') },
    );
    yield put(actions.setExportCompanies(response.response.url || ''));
  } catch (err) {
    console.log(err);
  }
}

export function* compareCompanies(
  dispatched: PayloadAction<CompareCompaniesRequest>,
) {
  try {
    const response: CompareCompaniesDataResponse = yield call(
      axios.post,
      API.COMPARE_COMPANIES,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setCompareCompanies(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* compareCompaniesExport(
  dispatched: PayloadAction<CompareCompaniesRequest>,
) {
  try {
    const response: CompareCompaniesExportResponse = yield call(
      axios.post,
      API.COMPARE_COMPANY_EXPORT,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setCompareCompaniesExport(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSWOTAnalysis(
  dispatched: PayloadAction<CompanySWOTAnalysis>,
) {
  try {
    const response: CompanySWOTResponse = yield call(
      axios.post,
      API.GET_SWOT_ANALYSIS,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setSWOTAnalysis(response?.response));
    } else {
      yield put(actions.setSWOTAnalysisFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setSWOTAnalysisFailed());
  }
}

export function* refreshSWOTAnalysis(
  dispatched: PayloadAction<CompanySWOTRefresh>,
) {
  try {
    const response: SWOTRefreshResponse = yield call(
      axios.post,
      API.REFRESH_SWOT_ANALYSIS,
      dispatched?.payload,
    );
    const { companyId } = dispatched.payload;
    yield put(actions.removeRefreshCompanyId(companyId));
    if (response?.status === true) {
      const { status, message, flag } = response.response;
      yield put(actions.setRefreshSWOTAnalysis(response?.response));
      if (status) {
        yield put(
          helperActions.setNotification({
            message: message as string,
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: true,
            companyId,
          }),
        );
        yield put(actions.setSwotRefreshedCompany(companyId));
      } else if (flag === 'updated') {
        yield put(actions.getSWOTAnalysis({ companyId }));
      } else {
        yield put(
          helperActions.setNotification({
            message: message || 'SWOT Analysis refresh failed. Please try again',
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: false,
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
    yield put(actions.removeRefreshCompanyId(dispatched?.payload?.companyId));
    yield put(actions.setRefreshSWOTLoading(false));
    yield put(
      helperActions.setNotification({
        message: 'SWOT Analysis refresh failed. Please try again',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }),
    );
  }
}

export function* getAccountPlanForm(dispatched: PayloadAction<GetAccountPlanRequest>) {
  try {
    const response: AccountPlanFormResponse = yield call(
      axios.post,
      API.ACCOUNT_PLAN_FORM,
      dispatched.payload,
    );
    if (response?.status === true) {
      yield put(actions.setAccountPlanForm(response.response));
      yield put(
        actions.setUpdatedUserAccountPlanDetails(
          response.userAccountPlanDetails as UserAccountPlanDetails,
        ),
      );
    } else {
      yield put(actions.setAccountFormLoading(false));
      handleException();
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setAccountFormLoading(false));
    handleException();
  }
}

export function* generateAccountPlan(
  dispatched: PayloadAction<GenerateAccountPlanRequest>,
) {
  console.log(dispatched);
  try {
    const response: GenerateAccountPlanResponse = yield call(
      axios.post,
      API.CREATE_ACCOUNT_PLAN,
      dispatched?.payload,
    );
    console.log(response, 'response from api');
    if (response.response.status === 'Generated') {
      const data = {
        companyId: dispatched?.payload.companyId,
        url: response?.response?.fileurl,
        previewUrl: '',
      };
      yield put(actions.setAccountPlan(data as GeneratedAccountPlan));
      yield put(helperActions.setNotification({
        message: response.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
        companyId: dispatched?.payload?.companyId,
        type: 'accountPlan',
        accountPlanFlag: true,
      }));
      yield put(actions.removeAccountPlanId(dispatched.payload.companyId));
    } else if (response.response.status === 'In Progress') {
      // console.log(response.response.account_plan_id)
      console.log(
        'Api returns "In Progress" and now connect socket connection',
      );
      dispatched.payload.connectSockets(response.response.account_plan_id);
    } else {
      yield put(actions.removeAccountPlanId(dispatched.payload.companyId));
      yield put(actions.setAccountPlanLoading(false));
      yield put(actions.setNewAccountPlan());
      yield put(actions.setGenerationFail());
      yield put(
        helperActions.setNotification({
          message: response.message,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }),
      );
    }
    // yield put(actions.removeAccountPlanId(dispatched.payload.companyId));
  } catch (err) {
    console.log(err);
    yield put(actions.setAccountPlanLoading(false));
    yield put(actions.setNewAccountPlan());
    yield put(actions.setGenerationFail());
    yield put(actions.removeAccountPlanId(dispatched.payload.companyId));
    yield put(
      helperActions.setNotification({
        message: 'Account Plan failed to generate',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }),
    );
  }
}

export function* getRecommendedDesignations(
  dispatched: PayloadAction<RecommendedDesignationsRequest>,
) {
  try {
    const response: RecommendedDesignationsResponse = yield call(
      axios.post,
      API.RECOMMENDED_DESIGNATIONS,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setRecommendedDesignations(response?.response));
    } else {
      yield put(actions.setRecommendedDesignations(['']));
      yield put(
        helperActions.setNotification({
          message: 'Unable to fetch designations',
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }),
      );
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setRecommendedDesignations(['']));
    yield put(
      helperActions.setNotification({
        message: 'Unable to fetch designations',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }),
    );
  }
}

export function* getPersonProfile(
  dispatched: PayloadAction<GetPersonProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const { payload } = dispatched;
    const { isBuyer } = payload;
    const parameters = { ...payload };
    if ('source' in parameters) {
      _.unset(parameters, 'source');
    }
    if ('entityName' in parameters) {
      _.unset(parameters, 'entityName');
    }
    const response: PersonDataResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_DATA,
      parameters,
    );
    if (response.status === true) {
      const executiveId = 'personId' in response.response.result[1].details[0]
        ? response.response.result[1].details[0].personId
        : 0;
      const data = {
        livesearchId: payload.personId,
        executiveId,
        color: '',
      };
      if (isBuyer) {
        yield put(actions.setBuyerPersonId(data));
      } else {
        yield put(actions.setLivePersonAccountPlan(data));
        yield put(
          actions.setLiveExecIds({
            liveId: payload.personId,
            executiveId,
          }),
        );
      }
      yield put(
        helperActions.callFlurryEvent(
          valueToObject(
            `accountPlan_clickAddPerson_${executiveId}`,
            startTime,
            {},
          ),
        ),
      );
    } else {
      yield put(actions.removeLivePersonLoadingId(payload.personId));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getAccountPlanStatus(dispatched:PayloadAction<AccountPlanStatusRequest>) {
  try {
    const response: AccountPlanStatusResponse = yield call(
      axios.post,
      API.GET_ACCOUNT_PLAN_STATUS,
      dispatched.payload,
    );
    if (response?.status === true) {
      yield put(actions.setAccountPlanStatus(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getAccountPlanHistory(dispatched: PayloadAction<AccountPlanHistoryRequest>) {
  const { offset } = dispatched.payload;
  try {
    const response: AccountPlanHistoryResponse = yield call(
      axios.post,
      API.ACCOUNT_PLAN_HISTORY,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setAccountPlanHistory({
        offset,
        accountPlanHistory: response?.response,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* deleteAccountPlan(dispatched: PayloadAction<DeleteAccountPlanRequest>) {
  try {
    const response: AccountPlanHistoryResponse = yield call(
      axios.post,
      API.DELETE_ACCOUNT_PLAN,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setDeleteAccountPlan(dispatched?.payload?.id));
    } else {
      yield put(actions.setDeleteAccountPlanLoading(false));
    }
    yield put(helperActions.setNotification({
      message: response?.message,
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: response?.status,
    }));
  } catch (err) {
    console.log(err);
  }
}

export function* companiesSaga() {
  yield takeLatest(actions.getCompanies.type, getCompanies);
  yield takeLatest(actions.getExportCompanies.type, getExportCompanies);
  yield takeLatest(
    actions.getRecommendedCompanies.type,
    getRecommendedCompanies,
  );
  yield takeEvery(actions.followCompany.type, followCompany);
  yield takeEvery(actions.unFollowCompanies.type, unFollowCompanies);
  yield takeLatest(actions.getCompanyBenefits.type, getCompanyBenefits);
  yield takeLatest(actions.getCompanyProfile.type, getCompanyProfile);
  yield takeLatest(actions.getCompanySecFilings.type, getCompanySecFilings);
  yield takeLatest(actions.getCompanyPulse.type, getCompanyPulse);
  yield takeLatest(actions.getCompanyIntentData.type, getCompanyIntentData);
  yield takeLatest(actions.getCompanyStaff.type, getCompanyStaff);
  yield takeLatest(actions.getPDFProfile.type, getDownloadProfile);
  yield takeLatest(actions.getPPTProfile.type, getDownloadProfile);
  yield takeLatest(actions.getStockChart.type, getStockChart);
  yield takeLatest(actions.getCompanyNews.type, getCompanyNews);
  yield takeLatest(actions.getCompanyVideos.type, getCompanyVideos);
  yield takeLatest(actions.getNotableStaff.type, getNotableStaff);
  yield takeLatest(actions.getBuyerStaff.type, getBuyerStaffResponse);
  yield takeLatest(
    actions.getCompanyWorkbenchStats.type,
    getCompanyWorkbenchStats,
  );
  yield takeLatest(actions.getExportNotableStaff.type, getExportNotableStaff);
  yield takeLatest(actions.compareCompanies.type, compareCompanies);
  yield takeLatest(actions.compareCompaniesExport.type, compareCompaniesExport);
  yield takeLatest(actions.getSWOTAnalysis.type, getSWOTAnalysis);
  yield takeEvery(actions.refreshSWOTAnalysis.type, refreshSWOTAnalysis);
  yield takeEvery(actions.getAccountPlanForm.type, getAccountPlanForm);
  yield takeEvery(actions.generateAccountPlan.type, generateAccountPlan);
  yield takeEvery(
    actions.getRecommendedDesignations.type,
    getRecommendedDesignations,
  );
  yield takeEvery(actions.getPersonProfile, getPersonProfile);
  yield takeEvery(actions.getAccountPlanHistory, getAccountPlanHistory);
  yield takeEvery(actions.getAccountPlanStatus.type, getAccountPlanStatus);
  yield takeEvery(actions.deleteAccountPlan, deleteAccountPlan);
}
