/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/ProfileShareSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';

import { API } from 'Utils/Constants';
import {
  BranchDeepLinkResponse,
} from 'Types/PersonTypes';
import { BranchIORequest } from 'Types/RequestBodies';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';

export function* getProfileShare(dispatched: PayloadAction<BranchIORequest>) {
  const startTime = getCurrentTime();

  try {
    const response: BranchDeepLinkResponse = yield call(
      axios.post,
      API.API_BRANCH,
      dispatched?.payload,
    );
    if (response) {
      const { data: { url } } = response;
      yield put(actions.setProfileShare(url));

      yield put(flurryActions.callFlurryEvent(
        valueToObject('share_options', startTime, {}),
      ));
    } else {
      yield put(actions.failProfileShare('something went wrong'));
    }
  } catch (err) {
    yield put(actions.failProfileShare('something went wrong'));
    console.log(err);
  }
}

export function* ProfileShareSaga() {
  yield takeLatest(actions.getProfileShare.type, getProfileShare);
}
